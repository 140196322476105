/* eslint-disable @typescript-eslint/no-explicit-any */
import { Module, GetterTree, ActionTree, MutationTree } from "vuex"
import { RootState } from "@/plugins/store/interfaces/RootState"
import { PayloadStore } from "@/plugins/store/interfaces/PayloadStore"
import productsData from "@/data/planos/ControleExpress.json"
const modulesPayload = (): PayloadStore => {
  return {
    pedido: {
      id: "",
      sku: "",
      preco: 0,
      franquia: 0,
      seguimento: "",
      fidelidade: {
        id: "",
      },
      aceite: {
        fidelidade: true,
      },
      servico: "",
    },
  }
}

const state: PayloadStore = modulesPayload()

const getters: GetterTree<PayloadStore, RootState> = {
  PayloadProductSKUOrder: ({ pedido }) => () => {
    return pedido.sku
  },
  PayloadFidelityOrder: ({ pedido }) => () => {
    return pedido.aceite.fidelidade
  },
  PayloadServiceOrder: ({ pedido }) => () => {
    return pedido.servico
  },
}

const actions: ActionTree<PayloadStore, RootState> = {
  ActionPayloadProductIdOrder ({ commit }, data) {
    commit("MutationPayloadProductIdOrder", data ? productsData[data].timId.base || productsData[data].timId : "")
  },
  ActionPayloadProductFidelityIdOrder ({ commit, getters }, data) {
    commit("MutationPayloadProductFidelityIdOrder", data ? productsData[getters.PayloadProductSKUOrder()].timId.fidelity : "")
  },
  ActionPayloadProductSKUOrder ({ commit, dispatch }, data) {
    commit("MutationPayloadProductSKUOrder", data)
    dispatch("ActionPayloadProductIdOrder", data)
  },
  ActionPayloadFidelityOrder ({ dispatch, commit }, data) {
    commit("MutationPayloadFidelityOrder", data)
    dispatch("ActionPayloadProductFidelityIdOrder", data)
  },
  ActionPayloadServiceOrder ({ commit }, data) {
    commit("MutationPayloadServiceOrder", data)
  },
  ActionPayloadProductCreateOrder ({ state, dispatch, getters }, data) {
    return new Promise(resolve => {
      dispatch("ActionPayloadFidelityOrder", /express/.test(data.seguiment) ? false : getters.PayloadFidelityOrder())
      dispatch("ActionPayloadProductSerguiment", data.seguiment)
      if (getters.PayloadFidelityOrder()) {
        dispatch("ActionPayloadProductPrice", data.price.fidelity)
      } else {
        dispatch("ActionPayloadProductPrice", data.price.base || data.price)
      }
      dispatch("ActionPayloadProductFranchise", data.franchise.total)
      resolve(state.pedido)
    })
  },
  ActionPayloadProductSerguiment ({ commit }, data) {
    commit("MutationPayloadProductSerguiment", data)
  },
  ActionPayloadProductPrice ({ commit }, data) {
    commit("MutationPayloadProductPrice", data)
  },
  ActionPayloadProductFranchise ({ commit }, data) {
    commit("MutationPayloadProductFranchise", data)
  },
}

const mutations: MutationTree<PayloadStore> = {
  MutationPayloadProductIdOrder ({ pedido }, data) {
    pedido.id = data
  },
  MutationPayloadProductFidelityIdOrder ({ pedido }, data) {
    pedido.fidelidade.id = data
  },
  MutationPayloadProductSKUOrder ({ pedido }, data) {
    pedido.sku = data
  },
  MutationPayloadFidelityOrder ({ pedido }, data) {
    pedido.aceite.fidelidade = data
  },
  MutationPayloadServiceOrder ({ pedido }, data) {
    pedido.servico = data
  },
  MutationPayloadProductSerguiment ({ pedido }, data) {
    pedido.seguimento = data
  },
  MutationPayloadProductPrice ({ pedido }, data) {
    pedido.preco = data
  },
  MutationPayloadProductFranchise ({ pedido }, data) {
    pedido.franquia = data
  },
}

const payloadStore: Module<PayloadStore, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

export default payloadStore
