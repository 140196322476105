import { Module, GetterTree } from "vuex"
import { RootState } from "@/plugins/store/interfaces/RootState"
import { CacheStore } from "@/plugins/store/interfaces/CacheStore"
const modulesCache = (): CacheStore => {
  return {
    rastreamentoUsuario: {},
  }
}

const state: CacheStore = modulesCache()

const getters: GetterTree<CacheStore, RootState> = {
  CacheRastreamentoUsuario: ({ rastreamentoUsuario }) => (data: Record<string, string>) => {
    if (data) rastreamentoUsuario = data
    return rastreamentoUsuario
  },
}

const cacheStore: Module<CacheStore, RootState> = {
  namespaced: true,
  state,
  getters,
}

export default cacheStore
