









































































import { Component } from "vue-property-decorator"
import { mixins } from "vue-class-component"
@Component
export default class ComponentToolbar extends mixins() {
  ENV = (env?: string): string|boolean => window.env(env)
  globalInformations = require("@/data/global/GlobalInformations.json")
}
