import Vue from "vue"
import VueRouter, { RouteConfig } from "vue-router"
Vue.use(VueRouter)
const routes: Array<RouteConfig> = [
  {
    path: "/",
    component: () => import(/* webpackChunkName: "RouteHomeView" */ "@/routes/RouteHomeView.vue"),
    children: [
      {
        path: "pedido/:sku?",
        components: {
          ViewCard: () => import(/* webpackChunkName: "RouteHomeView" */ "@/routes/order/RouteOrderView.vue"),
        },
        children: [
          {
            path: "cadastro",
            name: "formulario-de-pedido-cadastro",
            component: () => import(/* webpackChunkName: "formulario-de-pedido-cadastro" */ "@/views/order/ViewFormEntryData.vue"),
          },
          {
            path: "dados-pessoais",
            name: "formulario-de-pedido-dados-pessoais",
            component: () => import(/* webpackChunkName: "formulario-de-pedido-dados-pessoais" */ "@/views/order/ViewFormEntryData.vue"),
          },
          {
            path: "dados-de-pagamento",
            name: "formulario-de-pedido-dados-de-pagamento",
            component: () => import(/* webpackChunkName: "formulario-de-pedido-dados-de-pagamento" */ "@/views/order/ViewFormEntryData.vue"),
          },
          {
            path: "resumo-do-pedido",
            name: "formulario-de-pedido-resumo-do-pedido",
            component: () => import(/* webpackChunkName: "formulario-de-pedido-resumo-do-pedido" */ "@/views/order/ViewFormEntryData.vue"),
          },
          {
            path: "",
            name: "formulario-de-pedido",
            component: () => import(/* webpackChunkName: "Home" */ "@/views/ViewCard.vue"),
          },
        ],
      },
      {
        path: "",
        name: "Home",
        components: {
          ViewHero: () => import(/* webpackChunkName: "Home" */ "@/views/ViewHero.vue"),
          ViewCard: () => import(/* webpackChunkName: "Home" */ "@/views/ViewCard.vue"),
          ViewFaq: () => import(/* webpackChunkName: "Home" */ "@/views/ViewFaq.vue"),
        },
      },
    ],
  },
]
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
})
export default router
