


































































































import { Component } from "vue-property-decorator"
import { mixins } from "vue-class-component"
@Component
export default class ComponentFooter extends mixins() {
  globalInformations = require("@/data/global/GlobalInformations.json")
  footerBadgeLinks = [
    {
      url: `https://transparencyreport.google.com/safe-browsing/search?url=${location.hostname}`,
      image: "Google%20Site%20Seguro.png",
      texto: "Google Site Seguro",
      color: "white",
    },
  ]
}
