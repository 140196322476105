import Vue from "vue"
import Vuetify from "vuetify/lib/framework"
import pt from "vuetify/src/locale/pt"
import "./directives"
Vue.use(Vuetify)
export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#111111",
        secondary: "#c29032",
        accent: "#f5f5f5",
        grey: "#808080",
      },
    },
  },
  lang: {
    locales: { pt },
    current: "pt",
  },
  icons: {
    iconfont: "md",
  },
})
