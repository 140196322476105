import Vue from "vue"
import App from "@/App.vue"
import "@/registerServiceWorker"
import "@/functions/windowFunctions"
import store from "@/plugins/store"
import router from "@/plugins/router"
import vuetify from "@/plugins/vuetify"
import VueMeta from "vue-meta"
import VueJsonLD from "vue-jsonld"
Vue.use(VueMeta)
Vue.use(VueJsonLD)
Vue.config.productionTip = false
new Vue({
  store,
  router,
  vuetify,
  render: h => h(App),
}).$mount("#app")
