import Vue from "vue"
import Vuex, { StoreOptions } from "vuex"
import { RootState } from "./interfaces/RootState"
import cacheStore from "./store/cacheStore"
import payloadStore from "./store/payloadStore"
Vue.use(Vuex)
const store: StoreOptions<RootState> = {
  strict: false,
  modules: {
    cacheStore,
    payloadStore,
  },
}
export default new Vuex.Store<RootState>(store)
