

















import Vue from "vue"
import ComponentTolbar from "@/components/toolbar/ComponentToolbar.vue"
import ComponentFooter from "@/components/footer/ComponentFooter.vue"
export default Vue.extend({
  name: "App",
  components: {
    ComponentTolbar,
    ComponentFooter,
  },
})
